import { defineComponent as __define__ } from 'vue'
import { Slots as __Slots__ } from 'vue'
import SunBurstView from "@/components/SunBurstView.vue";


export function setup() {

const getContent = (selected: SunBurstNode) => {
  return selected && selected.data.phone
    ? `<div class="tw-text-center"><p>${
        selected.data.role
      }</p><p><a href="mailto:${selected.data.name
        .toLowerCase()
        .replace(
          / /g,
          "."
        )}@oecd.org">${selected.data.name
        .toLowerCase()
        .replace(/ /g, ".")}@oecd.org</a></p><p><a href="tel:${
        selected.data.phone
      }">${
        selected.data.phone
      }</a></p><p class="tw-my-8"><a tw-class="tw-bg-white tw-border tw-px-4 tw-py-2 tw-text-black tw-rounded tw-no-underline hover:tw-bg-gray-dark hover:tw-text-white hover:tw-border-gray-dark" href="mailto:?subject=Content details of ${
        selected.data.name
      }&body=Please find the contact details of ${
        selected.data.name
      } at this link: ${window.location}.">Share</a></p></div>`
    : "";
};

const open = (item: SunBurstNode) => {
  window.open(`tel:${item.data.phone}`);
};

/**
 * Current data.
 */
const data = {
  name: "OECD",
  children: require("@/assets/data/oecd-employees.json")
};


return { getContent, open, data }
}

const __default__ = { components: { SunBurstView } };
export default __define__({
  ...__default__,
  setup
})